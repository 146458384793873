<template>
  <div class="w100p pl10 pr10" >
   <div class="textc mb20">
     <p class="textc fs9  fwbold">{{type == 'group' ? '拼团模块' : type == 'isOrdinary' ? '精选模块' : '秒杀模块'}}</p>
     <p class="fs7">(图中所示商品为演示商品，请更改相关商品)</p>
   </div>
   <div class="">
     <p class="fs8 mb10">
       模块名称
     </p>
   <el-input :placeholder="listData.title" v-model="listData.title" maxlength="6" show-word-limit class="mb10 w200 ml20"></el-input>
   </div>
   <div class="" v-for="(item,index) in listData.list" :key="index">
     <p class="fs8 mt10 mb10">
       商品
     </p>
     <div class="bgf7fa ml10 pt5 pr5 pl5 pl5 pb10">
       <p>商品名称：{{item.goodsName}}</p>
       <div>
          <p  v-if="type == 'seckill'">商品价格：{{(item.minKillPrice/100)  || 0}}（{{(item.maxPrice/100)  || 0}}）元</p>
          <p  v-else-if="type == 'isOrdinary'">商品价格：{{(item.price/100)  || 0}} ~ {{(item.maxPrice/100)  || 0}}元</p>
          <p  v-else>商品价格：{{(item.minAssemblePrice/100)  || 0}}（{{(item.maxPrice/100)  || 0}}）元</p>
          <div class="d-flex mb10">
            <p>商品图片：</p>
            <img :src="item.goodPhoto" v-if="item.goodPhoto" class="w100 h100">
          </div>
          <div class="d-flex" v-if="showTips">
            <p>商品描述：</p>
            <el-input
              type="textarea"
              :rows="2"
              class="w240"
              placeholder="请输入内容"
              v-model="item.tips"
              maxlength="20"
              show-word-limit>
            </el-input>
          </div>
       </div>
       <div class="flex-a-b-c ml10 mt20 mb10">
        <el-button type="primary" @click="choice_tap(index)">{{type == 'group' ? '选择拼团商品' : type == 'isOrdinary' ? '选择精选商品' :  '选择秒杀商品'}}</el-button>

        <i class="el-icon-delete fs10" v-if="type !== 'group' && type !== 'isOrdinary'" @click="delete_seckill(index,listData.type)"></i>
      </div>
      
     </div>
     
   </div>
    <div class="textc">
      <el-button type="primary" v-if="type !== 'group' && type !== 'isOrdinary'"  class="mt20" @click="add_seckill(listData.type)">添加秒杀商品</el-button>
    </div>
    <el-dialog :title="type == 'group' ? '选择拼团商品' : type == 'isOrdinary' ? '选择精选商品' :  '选择秒杀商品'" 
               width="70%"
               :center="true"
               :modal-append-to-body="false"
                
               :visible="dialogTableVisible"
               @close="dialogClose">
      <commonTable :tableData="memberData"
                   :loading="loading"
                   @handleSizeChange="handleSizeChange"
                   @handleCurrentChange="handleCurrentChange"
                   v-if="memberData.length > 0"
                   :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择商品" >
              <template slot-scope="scope">
                <el-radio :label="scope.row.goodsId" v-model="selectGoodsId" @change="getTemplateRow(scope.$index,scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName"  align="center" label="产品名称" />
            
            <el-table-column prop="goodsTypeName" align="center" label="分类"  show-overflow-tooltip/>
            <el-table-column prop="personalWx" align="center" :label="(type == 'seckill' || type =='group') ? '原价格' : '价格'"  show-overflow-tooltip>
              <template slot-scope="scope">
                  {{(scope.row.minPrice || scope.row.price)/100+ '~'+(scope.row.maxPrice/100)}}元
              </template>
            </el-table-column>
            <el-table-column prop="personalWx" align="center" label="秒杀价格" v-if="type == 'seckill'"  show-overflow-tooltip>
              <template slot-scope="scope">
                  {{(scope.row.minKillPrice/100)+'~'+(scope.row.maxKillPrice/100)}}元
              </template>
            </el-table-column>
            <el-table-column prop="personalWx" align="center" label="拼团价格" v-if="type == 'group'"  show-overflow-tooltip>
              <template slot-scope="scope">
                  {{(scope.row.minAssemblePrice/100)+'~'+(scope.row.maxAssemblePrice/100)}}元
              </template>
            </el-table-column>
            <el-table-column prop="stockNum" align="center" label="库存"  show-overflow-tooltip/>
            <el-table-column prop="totalBuyNum" align="center" label="总销量"  show-overflow-tooltip/>
            <el-table-column prop="createTime" width="210px" align="center" label="秒杀活动时间" v-if="type == 'seckill'">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }} - {{scope.row.endTime| getDataTimeSec}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" width="210px" align="center" label="拼团活动时间" v-if="type =='group'">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }} - {{scope.row.endTime| getDataTimeSec}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" width="210px" align="center" label="发布时间" v-if="type =='isOrdinary'">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>
        <div class="flex-c-c w100P flex-column pt20 pb10" v-else-if="memberData.length == 0">
          <p class="mb20">暂无商品，需要设置商品</p>
          <el-button type="primary" plain @click="setGoods">
            去设置
          </el-button >
        </div>
        <div class="mt40 flex-c-c">
          <el-button type="primary" plain @click="cancel">
            取消
          </el-button>
          <el-button type="primary" @click="onConfirmShop">
            确认
          </el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>

import {
  getGoodsInfoPCShop,
} from "@/api/miniProgram";
import { getDataTimeSec } from "@/utils";
import commonTable from "@/components/common/commonTable";
export default {
  props:['listData','type','showTips'],
  data() {
    return {
      currentIndex:'',
      currentPage:1,
      pageSize:'',
      memberData:'',
      total:'',
      dialogTableVisible:false,
      loading:false,
      selectGoodsId:'',
      currentGoods:''
    };
  },
  components:{
    commonTable,
  },
  created(){
   this.queryShop()
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val,'yy-mm-dd');
    }
  },
  methods:{
    queryShop(){
      let data = {
        pageNum: this.currentPage
      }
      this.loading = true
      let than = this
      if(this.type == 'group'){
        data['isAssemble'] = 1
      }else if(this.type == 'isOrdinary'){
        
      }else{
        data['isKill'] = 1
      }
      
      getGoodsInfoPCShop(data)
      .then(res=>{
        if(res.data){
          this.loading = false;
          let than 
          let data = res.data
         
          if(data.data){
             this.total = data.data.pageInfo.total
             this.memberData = data.data.pageInfo.list || []
             this.memberData.forEach(item => {
              if (item.goodPhoto) {
                item.goodPhoto = item.goodPhoto.split(",")[0];
              } else {
                item.goodPhoto = "";
              }
               console.log(this.memberData)
             });
          }else{
            this.total = data.pageInfo.total
            this.memberData = data.pageInfo.list
            this.memberData.forEach(item => {
              if (item.goodPhoto) {
                item.goodPhoto = item.goodPhoto.split(",")[0];
              } else {
                item.goodPhoto = "";
              }
            });
          }
          
        }else{
          this.memberData = []
        }
        
      })
      .catch(err=>{
        this.loading = false;
      })
    },
    delete_seckill(index,type){
      
      if(this.listData.list.length <= 3){
        return  this.$message.error('秒杀商品不得少于三个')
        
      }
      
      this.$emit('delete_seckill',index,type)
    },
    add_seckill(type){
      if(this.listData.list.length > 5){
          return  this.$message.error('秒杀商品不得多于六个')
      }
      this.$emit('add_seckill',type)
    },
    choice_tap(index){
      this.queryShop()
      this.currentIndex = index
      this.selectGoodsId = this.listData.list[index].goodsId ? this.listData.list[index].goodsId : ''
      this.dialogTableVisible = true
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryShop()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryShop()
      this.$emit('dialogCurrentChange',val);
    },
    dialogClose(){
      this.dialogTableVisible = false
    },
    getTemplateRow(index,row){
      console.log(row)
      this.currentGoods = row
    },
    onConfirmShop(){
      let type = this.listData.type 
      let than = this
      let list =  than.listData.list
      for(var i in list){
        if(list[i].goodsId == than.currentGoods.goodsId){
          this.dialogTableVisible = false
          return than.$message.error('该商品已经选择，请选择其他商品')
        }
      }
      than.$emit('confirmSeckill',than.currentIndex,than.currentGoods,type)
      this.dialogTableVisible = false
      than.currentGoods  = '' 
      
    },
    //取消
    cancel(){
      this.dialogTableVisible = false
    },
    //设置商品
    setGoods(){
      if(this.type == 'group'){
        this.$router.push('/addAssemble')
          
      }else if(this.type == 'seckill'){
        this.$router.push('/addSpike') 
      }else{
        this.$router.push('/redactOrAdd') 
      }
       
    },
  }
};
</script>

<style lang="scss" scoped>

</style>